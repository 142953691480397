export const getEnvironment = () => {
  const envMode = import.meta.env.MODE;

  if (envMode === 'production') {
    return 'prod';
  } else if (envMode === 'staging') {
    return 'staging';
  } else if (envMode === 'dev') {
    return 'dev';
  } else {
    return 'local';
  }
};
