import { LoginButton } from '@/components/LoginButton/LoginButton';

import styles from './styles.module.scss';

export const LoginPage = () => {
  return (
    <div className={styles['frontpage-container']}>
      <img src="/frontpage.png" alt="" className={styles['frontpage-img']}></img>

      <div className={styles['frontpage-panel']}>
        <h5>Management Portal</h5>
        <h1>Welcome to Treble!</h1>
        <p>Please login to get things started</p>
        <LoginButton />
      </div>
    </div>
  );
};
