import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useQuery } from 'react-query';
import { useEffect } from 'react';

/** Hooks */
import { useAuth0 } from '@auth0/auth0-react';

/** Components */
import { LoginPage } from '@/pages/LoginPage';

/** Services */
import { getMyUser } from '@/apis/admin-api';

/** Styles */
import styles from './styles.module.scss';

/** Utils */
import { setAxiosInterceptors } from './utils';

/** Clients */
import adminAxiosClient from '@/apis/admin-api/axios';
import tassAxiosClient from '@/apis/tass-api/axios';
import stapiAxiosClient from '@/apis/stapi-api/axios';

export const PageWrapper = () => {
  const { isAuthenticated, isLoading, getAccessTokenSilently, logout } = useAuth0();

  useQuery('user', getMyUser, {
    enabled: isAuthenticated,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    setAxiosInterceptors([adminAxiosClient, tassAxiosClient, stapiAxiosClient], getAccessTokenSilently, logout);
  }, []);

  return (
    <main>
      {!isLoading ? (
        <>
          {isAuthenticated ? (
            <>
              <Outlet />
              <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
                toastClassName={styles['toast-notification']}
              />
            </>
          ) : (
            <LoginPage />
          )}
        </>
      ) : (
        <div></div>
      )}
    </main>
  );
};
