import { useCallback } from 'react';
import baseAxios from 'axios';
import axios from '@/apis/stapi-api/axios';

export const useDownloadLatestSdk = () => {
  const downloadLatestSdk = useCallback(async () => {
    try {
      // Fetch the binary store URL
      const { data: binaryStoreUrl } = await axios.get<string>('/login/GetLatestWheelUrl', {
        baseURL: import.meta.env.VITE_STAPI_URL,
      });

      // Get the filename from the URL
      const urlParams = new URLSearchParams(binaryStoreUrl);
      const contentDisposition = urlParams.get('response-content-disposition');
      const fileName = contentDisposition?.split('filename=')[1];

      // Fetch the .whl file from the binary store URL
      const binaryResponse = await baseAxios.get(binaryStoreUrl, { responseType: 'blob' });

      // Handling the file download
      const downloadUrl = window.URL.createObjectURL(new Blob([binaryResponse.data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', fileName ?? 'treble-sdk.whl');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error downloading the .whl file:', error);
    }
  }, []);

  return downloadLatestSdk;
};
