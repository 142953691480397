import { FC } from 'react';

import { Text } from '@/components/Shared/Text';
import { TextField } from '@/components/Shared/TextField';

import styles from './styles.module.scss';
import { toast } from 'react-toastify';

type CopyTokenProps = {
  token: string;
};

export const CopyToken: FC<CopyTokenProps> = ({ token }) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(token);
    toast.success('Copied to clipboard!');
  };

  return (
    <div className={styles['copy-token']}>
      <div className={styles['text-field-container']}>
        <TextField value={token} readOnly />
      </div>
      <button onClick={handleCopy} className={styles['copy-button']}>
        <Text type="bold-12px" color="#171717">
          Copy token
        </Text>
      </button>
    </div>
  );
};
