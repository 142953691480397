import { Button, buttonClasses } from '@mui/base';
import { Box, styled, SxProps, Theme } from '@mui/material';
import { FC } from 'react';

import { Text } from '@/components/Shared/Text';

const TrblSecondaryButton = styled(Button)`
  display: flex;
  align-items: center;
  background-color: #3c3c3c;
  height: 28px;
  width: 100%;
  padding: 0px 16px;
  border-radius: 4px;
  color: #dadada;
  cursor: pointer;
  border: 1px solid #555657;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  transition:
    0.15s background,
    0.15s color,
    0.15s border-color;
  &:hover {
    color: #dadada;
    background-color: #474747;
    border-color: #616161;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

type SecondaryButtonProps = {
  label: string;
  disabled?: boolean;
  icon?: React.ReactElement;
  width?: string | number;
  onClick?: (e: React.MouseEvent) => void;
  sx?: SxProps<Theme>;
} & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'>;

export const SecondaryButton: FC<SecondaryButtonProps> = ({ label, disabled, icon, onClick, width, sx, ...props }) => (
  <TrblSecondaryButton disabled={disabled} onClick={onClick} sx={{ width, ...sx }} {...props}>
    {icon && (
      <Box component="div" mr={label.length > 0 ? '8px' : ''} display={'flex'}>
        {icon}
      </Box>
    )}
    <Text type="semibold-12px">{label}</Text>
  </TrblSecondaryButton>
);
