import { GetTokenSilentlyOptions, LogoutOptions } from '@auth0/auth0-react';
import { Axios, AxiosError } from 'axios';

export const setAxiosInterceptors = async (
  axiosClients: Axios[],
  getAccessTokenSilently: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>,
  logout: (options?: LogoutOptions | undefined) => void
): Promise<void> => {
  axiosClients.forEach((client) => {
    client.interceptors.request.use(async (config) => {
      try {
        const accessToken = await getAccessTokenSilently();

        if (config.headers && accessToken) {
          config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        if (e.error === 'login_required' || e.error === 'invalid_grant') {
          logout({ logoutParams: { returnTo: window.location.origin } });
        }
      }
      return config;
    });

    client.interceptors.response.use(
      (response) => response,
      (error: AxiosError) => {
        if (
          error.response &&
          error.response.status === 403 &&
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (error.response.data as any)?.message === 'Trial expired'
        ) {
          logout({ logoutParams: { returnTo: 'https://www.treble.tech/license-contact' } });
        }

        return Promise.reject(error);
      }
    );
  });
};
