import { FC, ReactNode } from 'react';

/** Components */
import { Box } from '@mui/material';

/** Hooks */
import { UserMenu } from '../UserMenu';

import styles from './styles.module.scss';

type HeaderProps = {
  hasSidepanel?: boolean;
  children?: ReactNode;
};

export const Header: FC<HeaderProps> = ({ children, hasSidepanel = false }) => {
  return (
    <header className={`${styles['header-container']} ${styles['dark']}`}>
      <nav>
        <a href="/">
          <img src="/treble-logo.png" alt="Logo" />
        </a>
        <div style={{ flex: '1 1 auto' }}>{children}</div>
      </nav>
      {/* If we don't have a sidepanel we display the account profile icon, otherwise we do it in the side header */}
      {!hasSidepanel && (
        <Box component="div" display="flex" alignItems={'center'} paddingRight={'9px'}>
          <UserMenu />
        </Box>
      )}
    </header>
  );
};
