import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';

export const SdkSsoLogin = () => {
  const { loginWithRedirect } = useAuth0();

  // TODO: redirect in some other way? what to return?
  useEffect(() => {
    loginWithRedirect();
  }, []);

  return <></>;
};
