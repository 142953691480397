import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/material';

import styles from './styles.module.scss';

export const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Button
      className={styles['login-btn']}
      onClick={() =>
        loginWithRedirect({
          appState: {
            returnTo: `${window.location.href}`,
          },
        })
      }
      data-cy="logIn">
      Login
    </Button>
  );
};
