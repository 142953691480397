import { ForwardedRef, forwardRef } from 'react';
import { Button } from '@mui/base';
import { Box, buttonClasses, styled, SxProps, Theme } from '@mui/material';

const TrblTertiaryButton = styled(Button)`
  display: flex;
  align-items: center;
  background-color: transparent;
  color: #00f5ba;
  height: 28px;
  padding: 0px 12px 0px 12px;
  letter-spacing: 0.04em;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #00f5ba;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  transition:
    0.15s background,
    0.15s color,
    0.15s border-color,
    0.5s margin,
    0.4s opacity;
  font-weight: 500;
  line-height: 12px;
  min-height: 28px;
  font-size: 11px;
  &:hover:not(.Mui-disabled) {
    background-color: #00f5ba;
    border-color: #00f5ba;
    color: #272727;
    text-shadow: 0 0 #272727;

    path {
      fill: #272727;
    }
  }

  path {
    transition: 0.15s fill;
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

type TertiaryButtonProps = {
  label: string;
  disabled?: boolean;
  icon?: React.ReactElement;
  width?: string | number;
  onClick?: (e: React.MouseEvent) => void;
  sx?: SxProps<Theme>;
} & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'>;

export const TertiaryButton = forwardRef((props: TertiaryButtonProps, ref: ForwardedRef<HTMLButtonElement>) => (
  <TrblTertiaryButton ref={ref} sx={{ width: props.width, ...props.sx }} {...props}>
    {props.icon && (
      <Box component="div" mr={'5px'} display={'flex'}>
        {props.icon}
      </Box>
    )}
    <span>{props.label}</span>
  </TrblTertiaryButton>
));
