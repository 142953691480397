import { IconButton } from '@mui/material';
import { FC, useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { TrblClipboardIcon, TrblCompletedIcon } from '../Icons';
import { toast } from 'react-toastify';

type CodeBlockProps = {
  children: string;
  language: string;
  showLineNumbers: boolean;
};

export const CodeBlock: FC<CodeBlockProps> = ({ language = 'python', showLineNumbers = true, children }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = async () => {
    await navigator.clipboard.writeText(children);
    setIsCopied(true);
    toast.success('Copied to clipboard');
    setTimeout(() => setIsCopied(false), 2000); // Reset state after 2 seconds
  };

  return (
    <div style={{ position: 'relative' }}>
      <SyntaxHighlighter showLineNumbers={showLineNumbers} language={language} style={darcula}>
        {children}
      </SyntaxHighlighter>
      <span style={{ position: 'absolute', top: '7px', right: '7px' }}>
        <IconButton onClick={handleCopy} size="small">
          {!isCopied ? <TrblClipboardIcon /> : <TrblCompletedIcon />}
        </IconButton>
      </span>
    </div>
  );
};
