import { Button, buttonClasses } from '@mui/base';
import { Box, styled, SxProps, Theme } from '@mui/material';
import { FC } from 'react';

const TrblPrimaryButton = styled(Button)`
  background-color: #39bd9d;
  height: 28px;
  width: 100%;
  padding: 0px 16px;
  border-radius: 4px;
  font-weight: 800;
  font-size: 12px;
  color: #171717;
  cursor: pointer;
  border: 1px solid #2b8f77;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  transition:
    0.15s background,
    0.15s color,
    0.15s border-color;
  &:hover {
    background-color: #00f5ba;
    border-color: #39bd9d;
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

type PrimaryButtonProps = {
  label: string;
  disabled?: boolean;
  icon?: React.ReactElement;
  width?: string | number;
  onClick?: (e: React.MouseEvent) => void;
  sx?: SxProps<Theme>;
} & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'>;

export const PrimaryButton: FC<PrimaryButtonProps> = ({
  label,
  disabled = false,
  icon,
  onClick,
  width,
  sx,
  ...props
}) => (
  <TrblPrimaryButton disabled={disabled} onClick={onClick} sx={{ width, ...sx }} {...props}>
    {icon && (
      <Box component="div" mr={'8px'} display={'flex'}>
        {icon}
      </Box>
    )}
    <span>{label}</span>
  </TrblPrimaryButton>
);
