import { FC } from 'react';

import styles from '../styles.module.scss';

type TextFieldProps = {
  autoFocus?: boolean;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  placeholder?: string;
  value: string;
  readOnly?: boolean;
};

export const TextField: FC<TextFieldProps> = ({ placeholder, value, onChange, onBlur, autoFocus, readOnly }) => {
  return (
    <input
      autoFocus={autoFocus}
      type="text"
      className={styles['text-field']}
      placeholder={placeholder}
      value={value}
      onChange={onChange ? (event) => onChange(event.target.value) : undefined}
      onBlur={onBlur ? (event) => onBlur(event.target.value) : undefined}
      readOnly={readOnly}
    />
  );
};
