import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';

import { CopyToken } from '@/components/CopyToken';
import { TrblDownloadIcon, TrblFileIcon } from '@/components/Icons';
import { SecondaryButton, TertiaryButton } from '@/components/Shared/Buttons';

import { CodeBlock } from '@/components/CodeBlock';
import { getEnvironment } from '@/utils';
import { useDownloadLatestSdk } from './hooks/useDownloadLatestSdk';
import styles from './styles.module.scss';
import { getRefreshToken } from './utils';

export const SdkSsoInfo = () => {
  const [token, setToken] = useState<string | null>(null);
  const { getAccessTokenSilently, logout } = useAuth0();

  useEffect(() => {
    const getToken = async () => {
      const bearerToken = await getAccessTokenSilently();
      const refreshToken = getRefreshToken();
      let runningEnvironment = getEnvironment();
      runningEnvironment = runningEnvironment === 'local' ? 'dev' : runningEnvironment;

      if (runningEnvironment && refreshToken && bearerToken) {
        const tokenString = `${bearerToken}|${refreshToken}|${runningEnvironment}`;
        setToken(btoa(tokenString));
      }
    };

    getToken().catch((e) => {
      if (e.error === 'login_required' || e.error === 'invalid_grant') {
        logout({ logoutParams: { returnTo: window.location.origin } });
      }
    });
  }, []);

  const downloadLatestSdk = useDownloadLatestSdk();

  return (
    <div className={styles['sdk-info-page']}>
      <div className={styles['header']}>
        <h1>Treble SDK</h1>
        <div className={styles['header-actions']}>
          <SecondaryButton
            label="Documentation"
            width={'fit-content'}
            icon={<TrblFileIcon />}
            onClick={() => window.open('https://docs.treble.tech/treble-sdk', '_blank', 'noopener,noreferrer')}
          />
          <TertiaryButton
            onClick={downloadLatestSdk}
            icon={<TrblDownloadIcon fill={'#00f5ba'} />}
            label="Download latest version"
          />
        </div>
      </div>
      <div className={styles['info-section']}>
        <h2>SSO token</h2>
        <CopyToken token={token || ''} />
      </div>
      <div className={styles['info-section']}>
        <h2>Using the SSO token</h2>
        <p>
          There are two ways you can choose from when using the SSO token. <br /> You can set the SSO token as an
          environment variable or alternatively you can provide the SSO token when initializing the SDK.
        </p>
      </div>
      <div className={styles['info-section']}>
        <h4>Option 1: Set the SSO token as an environment variable</h4>
        <CodeBlock showLineNumbers language="python">
          {`# Note: On windows the command is "set" instead of "export" \nexport TSDK_SSO_TOKEN=${token}`}
        </CodeBlock>
        <p>Then you can initialize the SDK this way:</p>
        <CodeBlock showLineNumbers language="python">
          {'from treble_tsdk.tsdk import TSDK \ntsdk = TSDK()'}
        </CodeBlock>
      </div>
      <div className={styles['info-section']}>
        <h4>Option 2: Provide the SSO token when initializing the SDK</h4>
        <CodeBlock showLineNumbers language="python">
          {`from treble_tsdk.tsdk import TSDK, TSDKCredentials \ntsdk = TSDK(TSDKCredentials.sso('${token}'))`}
        </CodeBlock>
      </div>
    </div>
  );
};
