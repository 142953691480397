/** Hooks */
import { useAuth0 } from '@auth0/auth0-react';

/** Styles */
import styles from './styles.module.scss';

export const NoAccessPage = () => {
  const { logout } = useAuth0();

  return (
    <div className={styles['information-page']}>
      <button
        className={styles['logout-button']}
        onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
        Logout
      </button>
      <div className={`${styles['hero-image']} ${styles['hero-image-large']}`}>
        <div className={`${styles['information-page-header']} ${styles['no-subscriptions']}`}>
          <h1>{`Access denied`}</h1>
          <p>
            You do not have access to the Treble SDK.
            <br />
            Please contact your admin.
          </p>
        </div>
      </div>
    </div>
  );
};
