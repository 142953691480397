import { FC } from 'react';

/** Types */
import { IconProps } from '@/types';

export const TrblDownloadIcon: FC<IconProps> = ({ fill = '#999999' }) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.2221 9.48524C12.8317 9.48524 12.5151 9.78911 12.5151 10.164V11.2028C12.5145 11.6883 12.1045 12.0816 11.5987 12.082H2.40073C1.89497 12.0816 1.48496 11.6883 1.48431 11.2028V10.164C1.48431 9.78911 1.16778 9.48524 0.777313 9.48524C0.386847 9.48524 0.0703125 9.78911 0.0703125 10.164V11.2028C0.0703115 12.4388 1.1132 13.4412 2.40073 13.4428H11.5987C12.8874 13.4428 13.9321 12.4399 13.9321 11.2028V10.164C13.9321 9.98346 13.8572 9.8104 13.724 9.68304C13.5907 9.55567 13.4102 9.48449 13.2221 9.48524Z"
      fill={fill}
    />
    <path
      d="M7.00006 1C6.37538 1 5.86897 1.48615 5.86897 2.08584V6.1628C5.86897 6.24012 5.80368 6.3028 5.72314 6.3028H4.17206C3.95046 6.30289 3.74932 6.42714 3.65741 6.62071C3.56551 6.81428 3.59976 7.04154 3.74506 7.20216L6.57306 10.3247C6.68042 10.4435 6.83621 10.5118 7.00006 10.5118C7.16391 10.5118 7.31969 10.4435 7.42706 10.3247L10.2551 7.20216C10.4003 7.04154 10.4346 6.81428 10.3427 6.62071C10.2508 6.42714 10.0496 6.30289 9.82806 6.3028H8.27697C8.19643 6.3028 8.13114 6.24012 8.13114 6.1628V2.08584C8.13114 1.48615 7.62474 1 7.00006 1Z"
      fill={fill}
    />
  </svg>
);
