export const TrblExitIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <path
      d="M8.37931 9.4C8.03651 9.4 7.75862 9.66863 7.75862 10V11.5C7.75862 11.6657 7.61967 11.8 7.44828 11.8H1.55172C1.38033 11.8 1.24138 11.6657 1.24138 11.5V2.5C1.24138 2.33431 1.38033 2.2 1.55172 2.2H7.44828C7.61967 2.2 7.75862 2.33431 7.75862 2.5V4C7.75862 4.33137 8.03651 4.6 8.37931 4.6C8.72211 4.6 9 4.33137 9 4V2.2C9 1.53726 8.44421 1 7.75862 1H1.24138C0.555784 1 0 1.53726 0 2.2V11.8C0 12.4627 0.555784 13 1.24138 13H7.75862C8.44421 13 9 12.4627 9 11.8V10C9 9.66863 8.72211 9.4 8.37931 9.4Z"
      fill="#DADADA"
    />
    <path
      d="M13.7593 6.58425L11.4428 4.58408C11.277 4.48176 11.0638 4.47213 10.888 4.55903C10.7122 4.64592 10.6024 4.81525 10.6022 4.99962V6.24972H4.8108C4.52113 6.24972 4.25346 6.39269 4.10863 6.62476C3.96379 6.85683 3.96379 7.14275 4.10863 7.37482C4.25346 7.60689 4.52113 7.74985 4.8108 7.74985H10.6022V8.99996C10.6063 9.27455 10.8459 9.49623 11.1428 9.5C11.2495 9.50002 11.3539 9.47079 11.4428 9.41599L13.7593 7.41582C13.9097 7.32308 14 7.16696 14 6.99979C14 6.83261 13.9097 6.677 13.7593 6.58425Z"
      fill="#DADADA"
    />
  </svg>
);
