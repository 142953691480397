/** Types */
import { IconProps } from '@/types';

export const TrblCompletedIcon = ({ fill = '#00F5BA' }: IconProps) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.24052 3.22448C9.10713 3.12615 8.94014 3.08488 8.77631 3.10973C8.61248 3.13458 8.46524 3.22352 8.36702 3.35698L5.40502 7.37698L3.52152 5.86998C3.25139 5.66305 2.8654 5.71002 2.65278 5.97569C2.44016 6.24136 2.47892 6.62826 2.74002 6.84648L5.13152 8.75948C5.26501 8.86389 5.43488 8.91027 5.60292 8.88817C5.77095 8.86607 5.92305 8.77736 6.02502 8.64198L9.37502 4.09848C9.47316 3.96477 9.51412 3.79753 9.4889 3.6336C9.46367 3.46967 9.37432 3.32249 9.24052 3.22448Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 6C0 2.68629 2.68629 0 6 0C9.31222 0.00358242 11.9964 2.68778 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6ZM1.00028 5.99997C1.00028 8.76139 3.23885 11 6.00028 11C8.76045 10.9969 10.9972 8.76013 11.0003 5.99997C11.0003 3.23854 8.7617 0.999965 6.00028 0.999965C3.23885 0.999965 1.00028 3.23854 1.00028 5.99997Z"
      fill={fill}
    />
  </svg>
);
