import { FC } from 'react';

import styles from './styles.module.scss';

type TextTypes =
  | 'regular-9px'
  | 'regular-10px'
  | 'regular-11px'
  | 'regular-12px'
  | 'medium-8px'
  | 'medium-10px'
  | 'medium-11px'
  | 'medium-12px'
  | 'semibold-10px'
  | 'semibold-11px'
  | 'semibold-12px'
  | 'semibold-14px'
  | 'bold-11px'
  | 'bold-12px';

type TextProps = {
  type: TextTypes;
  color?: string;
  numberFontStyleEnabled?: boolean;
  ellipsis?: boolean;
  uppercase?: boolean;
} & React.HTMLAttributes<HTMLSpanElement>;

export const Text: FC<TextProps> = ({
  children,
  type,
  color = '#DADADA',
  style = {},
  className,
  numberFontStyleEnabled = false,
  ellipsis = false,
  uppercase = false,
}) => {
  return (
    <span
      style={{
        color,
        ...style,
      }}
      className={`${styles['text']} ${ellipsis ? styles['ellipsis'] : ''} ${uppercase ? styles['uppercase'] : ''} ${
        numberFontStyleEnabled ? styles['number-font-style'] : ''
      } ${styles[type]} ${className || ''}`}>
      {children}
    </span>
  );
};
