import { LocalStorageCache } from '@auth0/auth0-react';

export const getRefreshToken = () => {
  let refreshToken: string | undefined;

  const localStorageCache = new LocalStorageCache();
  const auth0CacheKey = localStorageCache.allKeys().find((key) => key.includes('offline_access'));
  if (auth0CacheKey) {
    const auth0Cache = localStorageCache.get(auth0CacheKey);
    //@ts-expect-error - auth0Cache.body.refresh_token is not typed
    refreshToken = auth0Cache?.body?.refresh_token;
  }

  return refreshToken;
};
