import { FC } from 'react';
import { Outlet } from 'react-router-dom';

/** Components */
import { Header } from './Header';

/** Styles */
import styles from './styles.module.scss';

export const LayoutWrapper: FC = () => {
  return (
    <div className={styles['layout-wrapper']}>
      <Header />
      <div className={styles['layout-content']}>
        <Outlet />
      </div>
    </div>
  );
};
