import { useAuth0 } from '@auth0/auth0-react';
import { Divider, IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { FC, useState } from 'react';

import { TrblExitIcon, TrblUserIcon } from '@/components/Icons';

import { menuPaperStyleOverrides } from './utils';

import styles from './styles.module.scss';

export const UserMenu: FC = () => {
  const { user, logout } = useAuth0();
  const userRoles: string[] = user ? user['https://treble.tech/roles'] : [];

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleUserProfileClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOutClick = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const gotoUrl = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <>
      <IconButton className={styles['user-profile-icon']} aria-label="Account profile" onClick={handleUserProfileClick}>
        <TrblUserIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: menuPaperStyleOverrides,
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <MenuItem className={`${styles['menu-item']} ${styles['info']}`}>
          <ListItemIcon className={styles['menu-icon']}>
            <TrblUserIcon />
          </ListItemIcon>
          {user?.email}
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => gotoUrl('https://docs.treble.tech/treble-sdk')} className={styles['menu-item']}>
          <ListItemIcon className={styles['menu-icon']}></ListItemIcon>
          Documentation
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogOutClick} className={styles['menu-item']}>
          <ListItemIcon className={styles['menu-icon']}>
            <TrblExitIcon />
          </ListItemIcon>
          Logout
        </MenuItem>
        {userRoles.includes('Superuser') && (
          <span
            onClick={(e) => e.stopPropagation()}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              height: '12px',
              fontSize: '8px',
            }}>
            v. {import.meta.env.VITE_APP_VERSION || '0.0.0'}
          </span>
        )}
      </Menu>
    </>
  );
};
